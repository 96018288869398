
import api from '@/api/co.api'
import app from '@/api/co.app'
import act from '@/api/co.action'
import notice from '@/api/co.notice'
import survey from '@/api/co.survey'
import activity from '@/api/co.activity'

import mixPreset from '@/pages/mixins/policy/mix.policy.preset'

const preNames = mixPreset.Names
const preParts = mixPreset.Parts

export default {
  data () {
    return {
      dbMain: {
        active: preNames.COLLECTION,
        tabs: [
          preParts[preNames.COLLECTION],
          preParts[preNames.UNDERSTAND]
        ]
      },
      dbSide: {
        event: {
          name: activity.comm.Types.PROJECT_REPORT,
          type: activity.comm.Types.PROJECT_REPORT,
          limit: 1,
          params: null,
          items: [],
          init: this.initEventParams,
        },
        psq: {
          name: survey.comm.Types.QUESTIONNAIRE,
          type: survey.comm.Types.QUESTIONNAIRE,
          limit: '1',
          params: null,
          items: [],
          init: this.initPsqParams,
        },
      }
    }
  },
  methods: {
    initPsqParams (obj) {
      const executed = function (res) {
      }

      obj.params = survey.main.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        status: 'publish',
        limit: obj.limit || 1,
        caches: obj.items,
        executed
      })
    },
    initEventParams (obj) {
      const executed = function (res) {
        if (res.status) {
        }
      }

      obj.params = activity.main.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        status: 'publish',
        limit: obj.limit || 1,
        caches: obj.items,
        executed
      })
    },
    onNoticeMore (ev, tabName) {
      let nav = null
      for (const i in this.dbMain.tabs) {
        const item = this.dbMain.tabs[i]
        if (item.name === tabName) {
          nav = item
          break
        }
      }

      if (nav) {
        this.toNotices(ev, nav)
      }
    },
    onGallery (ev) {
      act.navigate({ that: this, item: ev.item })
    },
    onNotice (ev, category) {
      act.navigate({
        that: this,
        pagename: mixPreset.DetailName,
        item: ev.item,
        category
      })
    },
    toNotices (ev, category) {
      act.navigate({
        that: this,
        pagename: mixPreset.ListName,
        item: {
          type: 'notices'
        },
        category
      })
    },
    initParamsBy (category = null) {
      // const me = this
      if (!category) {
        return
      }
      const executing = function () {
      }

      const executed = function (res) {
        // console.log('res: %o', res)
      }
      category.params = notice.getParams({
        siteId: app.init.siteId,
        type: category.type,
        topicId: category.topicId,
        categoryId: category.categoryId,
        limit: '8',
        caches: category.items,
        executing,
        executed
      })
    },
    initParams () {
      for (const i in this.dbMain.tabs) {
        const item = this.dbMain.tabs[i]
        this.initParamsBy(item)
      }

      for (const key in this.dbSide) {
        const item = this.dbSide[key]
        if (typeof item.init === 'function') {
          item.init(item)
        }
      }
    },
    loadItems (obj, reload = true) {
      const params = obj.params
      if (params) {
        params.reload = reload
        api.http.getItems(params)
      }
    },
    loadData (reload = true) {
      const items = this.dbMain.tabs
      for (const i in items) {
        const item = items[i]
        this.loadItems(item, reload)
      }

      for (const key in this.dbSide) {
        const item = this.dbSide[key]
        this.loadItems(item, reload)
      }
    },
  }
}
